<template>
  <div class="content-right rounded-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><router-link :to="{name: 'dashboard'}">Dashboard</router-link></li>
        <li class="breadcrumb-item "><router-link :to="{name: 'employees'}">Employees</router-link></li>
        <li class="breadcrumb-item active">{{employee.p.firstname + ' ' + employee.p.lastname}}</li>
      </ol>
    </nav>
    <div
      class="content-body"
      id="fullHeight"
      style="min-height: calc(100vh - 135px)"
    >
      <div class="row">
          <div class="col-md-11">
          <h2 class="title2">Employee Profile</h2>
          </div>
          <div class="col-md-1">
          <router-link
            :to="{ name: 'employees' }"
            class="btn btn-outline-primary"
            >Back</router-link
          >
          </div>
      </div>
      <div v-if="this.$store.state.loader">
          <loader object="#f74b3d" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" disableScrolling="false" name="spinning"></loader>
      </div>
      <div v-else class="row">
        <div class="col-md-4">
          
          <div class="card">
            <div class="card-header">
              <div class="img-avatar">
                <img
                  class="avatar border-white"
                  :src="
                    employee.p.avatar != ''
                      ? profileImagePath(employee.p.avatar)
                      : asset('/images/faces/default_user.jpg')
                  "
                  alt="profile photo"
                />
              </div>
            </div>
            <div class="card-body">
              <table class="personal-tbl">
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td>
                      <span class="p_value"> {{ employee.p.firstname + ' '+ employee.p.lastname }} </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>
                      <span class="p_value"> {{ employee.p.emailaddress }} </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Mobile no.</td>
                    <td>
                      <span class="p_value">{{ employee.p.mobileno }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Employee ID</td>
                    <td>
                      <span class="p_value"> {{ employee.c.idno }} </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="card">
            <div class="card-header">
              <h2>Personal Information</h2>
            </div>
            <div class="card-body">
              <table class="personal-tbl">
                <tbody>
                  <tr v-if="employee.p.civilstatus != null && employee.p.civilstatus != 'NULL'">
                    <td><p>Civil Status</p></td>
                    <td>
                      <p>
                        {{
                          employee.p.civilstatus != null
                            ? employee.p.civilstatus
                            : "N/A"
                        }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td><p>Age</p></td>
                    <td>
                      <p>
                        {{ employee.p.age != null ? employee.p.age : "N/A" }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td><p>Gender</p></td>
                    <td>
                      <p>
                        {{
                          employee.p.gender != null ? employee.p.gender : "N/A"
                        }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td><p>Date of Birth</p></td>
                    <td>
                      <p>
                        {{
                          employee.p.birthday != null
                            ? employee.p.birthday
                            : "N/A"
                        }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td><p>Aadhar Number</p></td>
                    <td>
                      <p>
                        {{
                          employee.p.aadhar != null &&
                          employee.p.aadhar != ""
                            ? employee.p.aadhar
                            : "N/A"
                        }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td><p>Home Address</p></td>
                    <td>
                      <p>
                        {{
                          employee.p.homeaddress != null &&
                          employee.p.homeaddress != ""
                            ? employee.p.homeaddress
                            : "N/A"
                        }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td><p>Pan Number</p></td>
                    <td>
                      <p>
                        {{
                          employee.p.pan != null &&
                          employee.p.pan != ""
                            ? employee.p.pan
                            : "N/A"
                        }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <h4 class="ui">Designation</h4>
                    </td>
                  </tr>
                  <tr>
                    <td><p>Company</p></td>
                    <td>
                      {{
                        employee.c.company != null ? employee.c.company : "N/A"
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td><p>Department</p></td>
                    <td>
                      <p>
                        {{
                          employee.c.department ? employee.c.department : "N/A"
                        }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td><p>Position</p></td>
                    <td>
                      {{
                        employee.c.jobposition != null
                          ? employee.c.jobposition
                          : "N/A"
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td><p>Leave Privilege</p></td>
                    <td>{{ leavePrivilege }}</td>
                  </tr>
                  <tr>
                    <td><p>Employment Type</p></td>
                    <td>
                      <p>{{ employee.p.employmenttype }}</p>
                    </td>
                  </tr>
                  <tr>
                    <td><p>Employement Status</p></td>
                    <td>
                      <p>{{ employee.p.employmentstatus }}</p>
                    </td>
                  </tr>
                  <tr>
                    <td><p>Official Start Date</p></td>
                    <td>
                      <p>{{ employee.c.startdate  }}</p>
                    </td>
                  </tr>
                  <tr>
                    <td><p>Date Regularized</p></td>
                    <td>
                      <p>{{ employee.c.dateregularized  }}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["id"],
  methods: {
    asset(path) {
      var base_path = window._asset || "";
      return base_path + path;
    },
    profileImagePath(path) {
      return this.$baseUrl+'/faces/' + path;
    },
  },
  computed: {
    employee() {
      return this.$store.state.employeeProfile;
    },
    leavePrivilege() {
      if (
        this.$store.state.employeeProfile.leavetype &&
        this.$store.state.employeeProfile.leavegroup &&
        this.$store.state.employeeProfile.c.leaveprivilege
      ) {
        let lp = this.$store.state.employeeProfile.leavegroup.filter((lg) => {
          return lg.id == this.$store.state.employeeProfile.c.leaveprivilege;
        });
        let lpNames = "";
        lpNames += lp.map((item) => {
          return item.leavegroup;
        });
        return lpNames;
      } else {
        return "";
      }
    },
  },
  mounted() {
    this.$store.dispatch('switchLoader',true);
    this.$store.dispatch("loadEmployeeProfile", this.$route.params.id);
  },
};
</script>
